<template>
  <r-container full-width class="pa-0">
    <r-meta
      title="doctor | about us"
      description="about us page"
      :url="$helper.url() + $route.path"
    ></r-meta>
    <r-container>
      <div class="r-text-editor" v-html="$helper.cleanXss(d)"></div>
    </r-container>
  </r-container>
</template>

<script>
export default {
  name: "about_us",
  data() {
    return {
      d: "",
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios.get("/home/settings/about_us").then(({ data }) => {
        this.d = data;
      });
    },
  },
};
</script>
